import React from "react"
import { GridBox, Text } from "@thedgbrt/logology-ui-components"

const ErrorEmpty = () => (
  <GridBox colSpans={[[2, 4], [2, 4], [2, 6]]} type={4} withBgContainer>
    <Text standalone>
      <p>
        Go to your proposals and then click "save" next to a logo to add it to
        this list.
      </p>
    </Text>
  </GridBox>
)

export default ErrorEmpty
