import React, { useEffect } from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import API, { graphqlOperation } from "@aws-amplify/api"
import {
  Button,
  Grid4ProposalHeader,
  GridSeparator,
  GridSpacer,
  LogoListItemSimple,
  ScoreCardLinks,
  Spinner,
  makeRem,
} from "@thedgbrt/logology-ui-components"
import { fetchNotStarted } from "../../../helpers/state"
import { loadSavedProposal } from "../../../state/actions/savedProposals"
import { removeSavedProposal } from "../../../state/actions/proposalSaveList"
import ErrorUnavailable from "./ErrorUnavailable"
import { saveStatusText } from "../helpers"
import { trackSegmentEvent } from "../../../helpers/segment"
import { trackAmplitude } from "../../../helpers/amplitude"

const SavedProposalItem = ({
  attributes,
  concept,
  last,
  loadSavedProposal,
  removeSavedProposal,
  startupName,
}) => {
  useEffect(() => {
    if (concept && fetchNotStarted(concept)) {
      loadSavedProposal(API, attributes, startupName)
    }
  }, [concept]) // eslint-disable-line

  const {
    colorId,
    error,
    fontId,
    forType,
    groupId,
    personaId,
    status,
    symbolId,
  } = attributes
  if (!concept || !symbolId) return null

  const conceptType = symbolId.charAt(0) === "m" ? "monogram" : "picto"
  const symbolIdClean = symbolId.substring(1)
  const conceptId = symbolId.charAt(0) + groupId

  const linkBasePath = "/myaccount/proposals"
  const linkUrlParams = `?conceptId=${conceptId}&colorId=${colorId}&fontId=${fontId}&forType=${forType}&symbolId=${symbolIdClean}&type=${conceptType}&backToSave=true`

  const linkConfigure = `${linkBasePath}/details/${linkUrlParams}`
  const linkBuy = `${linkBasePath}/details/buy/${linkUrlParams}&overridePersona=${personaId}&backToSaveDirectly=true`

  const handleBuyClick = () => {
    const trackingParams = {
      item: symbolId,
      color: colorId,
      font: fontId,
      source: "save list",
    }

    trackSegmentEvent("track", "Get this button", trackingParams)
    trackAmplitude("Get this button", trackingParams)

    navigate(linkBuy)
  }

  const handleReconfigure = e => {
    e.preventDefault()
    trackSegmentEvent("track", "Click reconfigure logo")
    trackAmplitude("Click reconfigure logo")

    navigate(linkConfigure)
  }

  const handleRemoveClick = e => {
    e.preventDefault()
    const trackingParams = {
      item: symbolId,
      color: colorId,
      font: fontId,
      source: "save list",
    }

    trackSegmentEvent("track", "Try remove logo from save", trackingParams)
    trackAmplitude("Try remove logo from save", trackingParams)

    const confirmVal = window.confirm(
      "Are you sure you want to remove this item?"
    )
    if (confirmVal === true) {
      removeSavedProposal(API, graphqlOperation, symbolId)
      trackSegmentEvent(
        "track",
        "Remove logo from save - confirm",
        trackingParams
      )
      trackAmplitude("Remove logo from save - confirm", trackingParams)
    } else {
      trackSegmentEvent(
        "track",
        "Remove logo from save - cancel",
        trackingParams
      )
      trackAmplitude("Remove logo from save - cancel", trackingParams)
    }
  }

  const renderInner = () => {
    if (concept && concept.fetchStatus !== "ok") {
      return [
        <LogoListItemSimple
          color="#FFFFFF"
          copyright=""
          src={""}
          loading={true}
        />,
        <div
          style={{
            background: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: makeRem(200), // to avoid looking weird on mobile
          }}
        >
          <Spinner inverted />
        </div>,
      ]
    } else if (concept.data.preview.svgError === false) {
      const { color, designer, preview, year } = concept.data
      const copyright = `Logo Design © ${year} ${designer}`
      return [
        <LogoListItemSimple
          color={color}
          copyright={copyright}
          src={preview.svgUrl}
          loading={false}
        />,
        <ScoreCardLinks
          bottomLinks={
            <>
              {saveStatusText(error, status)}
              <span className="hide_mobile hide_tablet">
                <br />
              </span>
              <br />
              <a
                href={linkConfigure}
                className="inverted"
                onClick={handleReconfigure}
              >
                re-configure
              </a>
              &nbsp;&nbsp;
              <a
                className="inverted"
                href="/myaccount/saved-proposals/#"
                onClick={handleRemoveClick}
              >
                remove
              </a>
            </>
          }
          buttonEl={
            <Button alternative inverted onClick={() => handleBuyClick()}>
              get this logo →
            </Button>
          }
          subtext="starts at $69"
          title={concept.data.concept_title}
          meaning={concept.data.concept_meaning}
        />,
      ]
    } else return <ErrorUnavailable />
  }

  return (
    <>
      <GridSeparator />
      <Grid4ProposalHeader invert id={`logo-concept-${groupId}`}>
        {renderInner()}
      </Grid4ProposalHeader>
      {last ? null : (
        <GridSpacer desktop={96} mobile={72} tablet={96} type={4} />
      )}
    </>
  )
}

export default connect(
  ({ questionnaire, savedProposals }, ownProps) => {
    return {
      concept: savedProposals.itemDetails[ownProps.attributes.symbolId],
      startupName: questionnaire.startupName,
    }
  },
  { loadSavedProposal, removeSavedProposal }
)(SavedProposalItem)
