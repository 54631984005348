import React from "react"
import PrivateRoute from "../../../components/auth/PrivateRoute/loadable"
import ProfileRoute from "../../../components/auth/ProfileRoute"
import SavedList from "../../../components/Proposal/SavedList"

const SavedProposalPage = props => (
  <PrivateRoute url={props.location.href}>
    <ProfileRoute>
      <SavedList url={props.location.href} />
    </ProfileRoute>
  </PrivateRoute>
)

export default SavedProposalPage
