import React from "react"
import { Text } from "@thedgbrt/logology-ui-components"

// error can happen when startup name has changed and became longer
// becoming incompatible with the saved font
// (reminder: font are made available depending on startup name length, see LogoProposals lambda function)
const ErrorUnavailable = () => (
  <div style={{ background: "white", marginRight: "1px" }}>
    <Text standalone>
      We couldn't load the logo at this time. Try to refresh the page or contact
      us at team@logology.co.
    </Text>
  </div>
)

export default ErrorUnavailable
