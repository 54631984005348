import React, { useEffect } from "react"
import { connect } from "react-redux"
import AdminHeader from "../../AdminHeader"
import SEO from "../../seo"
import SavedListHeader from "./SavedListHeader"
import SavedProposalItem from "./SavedProposalItem"
import { scrollBackInPosition } from "../helpers"
import { trackSegmentEvent } from "../../../helpers/segment"
import { trackAmplitude } from "../../../helpers/amplitude"
import ErrorEmpty from "./ErrorEmpty"

const SavedList = ({ canLoadCatalog, savedProposalKeys, url }) => {
  useEffect(() => {
    scrollBackInPosition()
    trackSegmentEvent("track", "Proposals save list")
    trackAmplitude("Proposals save List")
  }, []) // eslint-disable-line

  const renderProposals = () => {
    if (canLoadCatalog === false) return null

    let proposalKeys = Object.keys(savedProposalKeys)
    if (proposalKeys.length < 1) return <ErrorEmpty />

    return proposalKeys
      .sort((a, b) => {
        const pA = savedProposalKeys[a]
        const pB = savedProposalKeys[b]

        return new Date(pB.updatedAt) - new Date(pA.updatedAt)
      })
      .map((key, index) => {
        const savedProposal = savedProposalKeys[key]
        return (
          <SavedProposalItem
            attributes={savedProposal}
            last={index >= Object.keys(savedProposalKeys).length - 1}
            key={savedProposal.symbolId}
          />
        )
      })
  }

  return (
    <>
      <AdminHeader url={url} />
      <SEO title="My saved logos" />
      <SavedListHeader />
      {renderProposals()}
    </>
  )
}

export default connect(
  ({ proposalSaveList, user }) => {
    let canLoadCatalog = user.profile.personaId !== null

    return {
      canLoadCatalog,
      savedProposalKeys: proposalSaveList,
    }
  },
  {}
)(SavedList)
