import React from "react"
import {
  GridBox,
  GridContainer,
  GridSeparator,
  GridSpacer,
  Standalone,
  Title,
} from "@thedgbrt/logology-ui-components"

const SavedListHeader = () => (
  <>
    <GridContainer type={4}>
      <GridBox colSpans={[[2, 5], [3, 5], [4, 6]]} type={4}>
        <Standalone>
          <Title level={2} className="size-alt">
            My saved logos
          </Title>
        </Standalone>
      </GridBox>
    </GridContainer>

    <GridSeparator />

    <GridSpacer desktop={72} mobile={42} tablet={72} type={4} />
  </>
)

export default SavedListHeader
